import type { Environment } from './types';
import { sharedDevAuEnv, sharedDevEnv, sharedKinEnv } from './shared';

export const env: Environment = {
  ...sharedDevEnv,
  ...sharedKinEnv,
  ...sharedDevAuEnv,
  adminAppUrl: 'https://dev.admin.kinfertility.com.au',
  logoUrl: 'https://assets.kinfertility.com.au/kin-logo.svg',
};
